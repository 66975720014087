<template>
	<div id="logistics-select">
		<!--Topbar-->
		<TopBar
			:variantOptions="variants"
			:yearOptions="yearOptions"
			:selectedYear="selectedYear"
			@yearChange="handleYearChange"
		/>

		<!--Main section-->
		<SelectionMain
			:totalWeeks="WKS_IN_YR"
			:exportSelections="exportSelections"
			:planWeeks="planWeeks"
			@exportSelect="handleExportSelect"
			@exportSelectAll="handleExportSelectAll"
		/>

		<!--exports-->
		<Export
			:exportSelections="exportSelections"
			:planWeeks="planWeeks"
		/>
	</div>
</template>

<script>
import SelectionMain from './SelectionMain.vue'
import TopBar from './TopBar.vue'
import Export from './Export.vue'
import axios from 'axios';

export default {
	name: "SelectionSect",
	props: ['farm'],
	data(){
		return {
			WKS_IN_YR: 52,
			variants: [],
			exportSelections : new Array(this.WKS_IN_YR).fill(false),
			planWeeks: null,
			selectedYear: new Date().getFullYear().toString(),
		}
	},
	computed: {
		yearOptions(){
			const date = new Date();
			return [(date.getFullYear() - 1).toString() ,(date.getFullYear()).toString(), (date.getFullYear() + 1).toString()]
		}
	},
	mounted(){
		this.getData();
		this.getVariants();
	},
	methods : {
		/**
		 * handleExportSelect - state management to handle export selection
		 * @param {*} value 
		 * @param {*} index 
		 */
		handleExportSelect(value, index)
		{
			this.exportSelections[index] = value
			const arrCpy = [...this.exportSelections]
			this.exportSelections = arrCpy
		},
		/**
		 * handleExportSelectAll - state management to handle export all selection
		 * @param {*} value 
		 */
		handleExportSelectAll(value)
		{
			this.exportSelections = new Array(this.WKS_IN_YR).fill(value)
		},
		/**
		 * handleYearChange - state manamgement for year fitler
		 * @param {*} value 
		 */
		handleYearChange(value)
		{
			this.selectedYear = value
		},
		getData()
		{
			axios.get(`${this.farm}/logistics/year/${this.selectedYear}`)
			.then((data) => this.planWeeks = data?.data?.data)
			.catch((error) => {
				console.error("fetching logistics data, ", error)
				this.$bvToast.toast(error.response.data?.error, {
						title: 'Error',
						autoHideDelay: 3000,
						solid : true,
						variant: 'danger'
					})
			})
		},
		getVariants(){
			axios.get(`/${this.farm}/variantManagement/variants`)
			.then((res) => {
				let avariants = res.data.active;
				avariants = avariants.map((v) => {
					v.label = `${v.code || ""} ${v.colour} ${v.name || ""} ${v.group} - ${v.source}`;
					v.value = `${v.code || ""} ${v.colour} ${v.name || ""} ${v.group}`;
					return v;
				});
				this.variants = avariants;
			})
			.catch((error) => {
				console.error(error);
				this.$emit('error', `Error retrieving variants`);
			})
        },
	},
	watch:
	{
		/**
		 * selectedyear and famr - everytime selectedYear or farm changes and page loads, clear selections and get data from backend
		 */
		selectedYear : {
			handler(){
				// clear selections
				this.$store.commit('setCurrentSelectedWeek', -1)
				this.$store.commit('setSelectedPlans', [])
				this.$store.commit('setSelectedVariantFitler', "")
				this.exportSelections = new Array(this.WKS_IN_YR).fill(false)

				this.getData()
			},
			immediate: true
		},
		farm: {
			handler(){
				this.getData();
				this.getVariants();
			},
		}
	},
	components: {
		TopBar,
		SelectionMain,
		Export,
	},
}
</script>

<style scoped>
#logistics-select{
	box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.1);
}

</style>