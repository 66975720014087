<template>
	<div id="logistics-select-topbar">
		<b-row class="logistics-select-topbar__row">
			<!--Week and date display-->
			<b-col cols="4" class="logistics-select-topbar__cols">
				<div class="logistics-select-topbar__time"> 
					<div>
						<div class="logistics-select-topbar__time__week">week {{ currentSelectedWeek + 1 }}</div>
						<div class="logistics-select-topbar__time__date">{{ evalDateRanges(currentSelectedWeek).start }} - {{ evalDateRanges(currentSelectedWeek).end }} </div>
					</div>
				</div>
			</b-col>

			<!--variant filters-->
			<b-col cols="4" class="logistics-select-topbar__cols">
				<div
				class="logistics-select-topbar__variant-filter-container"
				>
					<v-select
						class="logistics-select-topbar__variant-filter"
						:options="variantOptions"
						:reduce="option => option.value"
						v-model="selectedVariantFilter"
						placeholder="Variant"
					></v-select>
				</div>
			</b-col>

			<!--year filters-->
			<b-col cols="4" class="logistics-select-topbar__cols">
				<div
				class="logistics-select-topbar__variant-filter-container"
				>
					<v-select
						class="logistics-select-topbar__variant-filter"
						label="Select"
						:options="yearOptions"
						:value="selectedYear"
						:searchable="false"
						@input="(val) => this.$emit('yearChange', val)"
						:clearable="false"
					></v-select>
				</div>
			</b-col>

			<!--farm toggle-->
			<!-- <b-col cols="1" class="logistics-select-topbar__cols">
				<div
				class="logistics-select-topbar__farm-toggle-container"
				>
					<img
					src="../../../assets/visibleeye-icon.svg"
					/>
				</div>
			</b-col> -->
		</b-row>
	</div>
</template>

<script>

export default {
	name: "TopBar",
	props : {
		variantOptions : Array,
		selectedYear: String,
		yearOptions: Array
	},
	computed : {
		currentSelectedWeek(){
			return this.$store.state.currentSelectedWeek;
		},
		selectedVariantFilter:{
			get(){
				return this.$store.state.selectedVariantFitler;
			},
			set(e){
				this.$store.commit('setSelectedVariantFitler', e)
			}
		}
	},
	methods : {
		/**
		 * evalDateRanges - given a week number, evaluate its date ranges
		 */
		evalDateRanges(week)
		{
			const res = {
				start: null,
				end : null,
			}
			let firstDay = new Date(new Date().getFullYear(), 0, 1);
			let currWeek = 0;
			
			if (week > 51 || week < 0)
				return res;
				
			for(; currWeek < week; ++currWeek)
			{
				firstDay.setDate(firstDay.getDate() + 7);
			}
			res.start = new Date(firstDay).toLocaleDateString("en-US");
			res.end = new Date(firstDay.setDate(firstDay.getDate() + 7)).toLocaleDateString("en-US");
			return res;
		}
	},
	data(){
		return {
			varFitler : ""
		}
	},
}
</script>

<style>
#logistics-select-topbar{
	padding: 18px 0;
	position: sticky;
    top: 0px;
    z-index: 2;
    background-color: white;
	border-bottom: 2px solid #F6F6F6;
}
.logistics-select-topbar__time
{
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
}
.logistics-select-topbar__row
{
	margin: 0;
}

.logistics-select-topbar__time__week
{
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 600;
	font-size: 21px;
	color: #4D5061;
}

.logistics-select-topbar__time__date
{
	font-style: normal;
	font-weight: 400;
	font-size: 15px;
	color: #4D5061;
}

.logistics-select-topbar__variant-filter
{
	width: 166px;
	padding: 0 0 0 27px;
}

.logistics-select-topbar__variant-filter .vs__dropdown-toggle
{
	background: #F0F0F0;
	border-radius: 6px;
	
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	color: #4D5061;
}
.logistics-select-topbar__variant-filter .vs__selected-options
{
	flex-wrap: nowrap;
}

.logistics-select-topbar__variant-filter-container
{
	height: 100%;
	align-items: center;
	display: flex;
}

.logistics-select-topbar__farm-toggle-container
{
	height: 100%;
	align-items: center;
	display: flex;
	cursor: pointer;
}
</style>