const generateColorForVariant = (zonename) => {
	const colors = [
		"#FFC793",
		"#9EE8FF",
		"#FFADFC",
		"#AAFFBD",
		"#febab1",
		"#b1c2fe",
		"#feb1df",
		"#8bffea",
		"#b39bf9",
		"#c58e98"
	]

	const borderColors = [
		"#FFB46F",
		"#64DAFF",
		"#FF75F9",
		"#64FF86",
		"#fd8c7c",
		"#7c99fd",
		"#fd7cc9",
		"#3effdc",
		"#8158f5",
		"#9e4354"
	]

	let checksum = 0;
	for (let index = 0; index < zonename.length; index++) {
		let char = zonename.charCodeAt(index);
		checksum += char
	}
	return {
		color : colors[checksum % colors.length],
		borderColor : borderColors[checksum % borderColors.length]
	}
}

export default generateColorForVariant;