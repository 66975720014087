<template>
	<div id="logistics-display-topbar">
		<b-row class="logistics-display-topbar__container">
			<!--Week display-->
			<b-col cols="4">
				<div class="logistics-display-topbar__week-display">Week {{ currentSelectedWeek + 1 }}</div>
			</b-col>

			<b-col cols="4">
			</b-col>

			<!-- unit filter-->
			<b-col cols="4" class="logistics-display-topbar__unit-filter-container">
				<v-select
				class="logistics-display-topbar__unit-filter"
				label="Select"
				:options="unitOptions"
				:value="selectedUnitFilter"
				:searchable="false"
				:clearable="false"
				@input="(e) => $emit('filterChanged', e)"
				></v-select>
			</b-col>
		</b-row>
	</div>
</template>

<script>

export default {
	name: "TopBar",
	props : {
		selectedUnitFilter : String,
	},
	computed : {
		currentSelectedWeek()
		{
			return this.$store.state.currentSelectedWeek;
		}
	},
	data ()
	{
		return {
			unitOptions : ["G/UNIT", "UNITS"],
		}
	}
}
</script>

<style>
.logistics-display-topbar__container {
	padding: 18px 20px;
	border-bottom: 2px solid #F6F6F6;
}

.logistics-display-topbar__week-display {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 5px 36px;
	background: #E4F8EE;
	border: 1px solid #32C57C;
	border-radius: 6px;
	color: #32C57C;
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	font-size: 19px;
	line-height: 28px;
}

.logistics-display-topbar__unit-filter-container
{
	align-items: center;
	display: flex;
}

.logistics-display-topbar__unit-filter
{
	width: 166px;
	padding: 0 0 0 27px;
}

.logistics-display-topbar__unit-filter .vs__dropdown-toggle
{
	background: #F0F0F0;
	border-radius: 6px;
	
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	color: #4D5061;
}
</style>