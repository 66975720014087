import { render, staticRenderFns } from "./DisplaySect.vue?vue&type=template&id=7720b603&scoped=true"
import script from "./DisplaySect.vue?vue&type=script&lang=js"
export * from "./DisplaySect.vue?vue&type=script&lang=js"
import style0 from "./DisplaySect.vue?vue&type=style&index=0&id=7720b603&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7720b603",
  null
  
)

export default component.exports