<template>
	<div
	id="logistics-select-main"
	class="scroll"
	> 
	<!--select all-->
	<b-row style="margin:0">
		<b-col cols="4" class="logistics-select-main__week-selall">
			<div style="
			cursor: pointer;
			"
			@click="$emit('exportSelectAll', false)"
			>
				deselect all <i class="fas fa-times"></i>
			</div>

			<div
			style="
			color:#029F51;
			text-decoration: underline solid ;
			cursor: pointer;
			"
			@click="$emit('exportSelectAll', true)"
			>
				Select all
			</div>
		</b-col>

		<b-col cols="4" v-if="!planWeeks" class="logistics-select-main__week-selall">
			Loading ...
		</b-col>
	</b-row>

	<!-- weeks -->
	<b-row
	v-for="week in totalWeeks"
	:key="week"
	style="align-items: center;margin: 0;min-height: 64px;"
	:class="{
		'logistics-select-main__week-sel' : true,
		'logistics-select-main__week-sel--active' : currentSelectedWeek == week - 1,
		'logistics-select-main__week-sel--today' : isTodaySelectedWeek(week - 1)
	}"
	:ref="'week'+week"
	>
		<div :class="{
			'no' : true,
			'haha' : currentSelectedWeek == week - 1,
		}">
		</div>
		<b-col cols="4" class="logistics-select-main__week-sel__col">
			<div
			:class="{
				'logistics-select-main__week-sel__week' : true,
				'logistics-select-main__week-sel__week--selected' : false,
			}"
			>
			<b-form-checkbox
			:checked="exportSelections[week - 1]"
			@change="(v) => handleExportSelect(v, week - 1)"
			size="sm"
			class="logistics-select-main__week-sel__week__check"
			>
			</b-form-checkbox>
			<div
			:class="{
				'logistics-select-main__week-sel__week__word' : true,
				'logistics-select-main__week-sel__week__word--selected' : exportSelections[week - 1],
				'logistics-select-main__week-sel__week__word--active' : currentSelectedWeek == week - 1,
			}"
			@click="()=>handleWeekSelect(week - 1)"
			>
				Week {{week}}
			</div>
			</div>
		</b-col>
		<b-col
		cols="8"
		@click="()=>handleWeekSelect(week - 1)"
		>
		<div
		class="logistics-select-main__week-sel__col__variant-list"
		>
			<!-- variants -->
			<div
			v-for="variant in aggregateVariants(planWeeks ? planWeeks[week - 1] : [])"
			:key="variant._id"
			class="logistics-select-main__week-sel__col__variant"
			:style="{
				'background-color' :currentSelectedWeek == week - 1? generateColorForVariant(variant.name).color : 'transparent',
				'border-color' : generateColorForVariant(variant.name).borderColor,
			}"
			>
				{{ variant.name }}
			</div>
		</div>
			
		</b-col>
	</b-row>
	</div>
</template>

<script>
import generateColorForVariant from '../scripts/generateColorForVariant';


export default {
	name: "SelectionMain",
	props : {
		totalWeeks: Number,
		exportSelections: Array,
		planWeeks: Array,
	},
	computed : {
		currentSelectedWeek()
		{
			return this.$store.state.currentSelectedWeek;
		},
		selectedVariantFilter()
		{
			return this.$store.state.selectedVariantFitler;
		}
	},
	methods : {
		handleExportSelect(value, index)
		{
			this.$emit('exportSelect', value, index)
		},
		handleWeekSelect(week)
		{
			this.$store.commit('setCurrentSelectedWeek', week);
			this.$store.commit('setSelectedPlans', this.planWeeks? this.planWeeks[week] : [])
		},
		/**
		 * aggregateVariants - given a planting plan, determine which variants to show
		 * @param {*} plans 
		 */
		aggregateVariants(plans)
		{
			const result = [];
			plans.forEach(plan => {
				plan.variants.forEach(variant => {
					if (this.shouldDisplayVariant(variant.name))
						result.push(variant)
				});
			});

			return result;
		},
		/**
		 * evalDateRanges - given a week number, evaluate its date ranges
		 */
		evalDateRanges(week)
		{
			const res = {
				start: null,
				end : null,
			}
			let firstDay = new Date(new Date().getFullYear(), 0, 1);
			let currWeek = 0;
			
			if (week > 51 || week < 0)
				return res;

			for(; currWeek < week; ++currWeek)
			{
				firstDay.setDate(firstDay.getDate() + 7);
			}
			res.start = new Date(firstDay).toLocaleDateString("en-US");
			res.end = new Date(firstDay.setDate(firstDay.getDate() + 7)).toLocaleDateString("en-US");
			return res;
		},
		/**
		 * getWeekNumber - given a date, get the week number
		 * @param {*} date 
		 */
		getWeekNumber(date){
			let currentDate = new Date(date);
			let startDate = new Date(new Date(date).getFullYear(), 0, 1);
			var days = Math.floor((currentDate - startDate) / (24 * 60 * 60 * 1000)); 
			var weekNumber = Math.ceil(days / 7);

			return weekNumber;
		},
		/**
		 * isTodaySelectedWeek - determine if today is within week number
		 * @param {*} week 
		 */
		isTodaySelectedWeek(week){
			const today = new Date();
			const range = this.evalDateRanges(week)
			return today <= new Date(range.end) && today >= new Date(range.start)
		},
		generateColorForVariant,
		shouldDisplayVariant(name){
			return this.selectedVariantFilter == name || !this.selectedVariantFilter
		},
	},
	/**
	 * mounted - check red line render position and store selected week as todays week
	 */
	mounted()
	{
		// set the red line for the current cal week
		const weekToday = this.getWeekNumber(new Date()) + 1;
		const el = this.$refs['week'+weekToday];
		if (el)
			el[0].scrollIntoView(false)

		// set store for the selected week
		if (this.planWeeks)
		{
			this.$store.commit('setCurrentSelectedWeek', this.getWeekNumber(new Date()))
			this.$store.commit('setSelectedPlans', this.planWeeks[this.getWeekNumber(new Date())])
		}
	}
}
</script>

<style scoped>
.no{
	left: -8px;
	width: 8px;
	height: 100%;
	background-color: #32C57C;
	position: absolute;
	transition: left .3s linear;
}
.haha{
	left: 0;
	transition: left .3s linear;
}


#logistics-select-main {
	max-height: 450px;
	overflow: auto;
	scroll-behavior: smooth;
}

.logistics-select-main__week-sel {
	position: relative;
	align-items: center;
	margin: 0;
	min-height: 64px;
	background-color: white;
	transition: background-color .5s linear;
}

.logistics-select-main__week-sel--active{
	background-color: #e4f7ee;
	transition: background-color .5s linear;
}

.logistics-select-main__week-sel--today{
	border-bottom: 2px solid red;
}

.logistics-select-main__week-sel__col{
	border-right: 2px solid #F6F6F6;
	display: flex;
	align-items: center;
	min-height: 64px;
	justify-content: center;
}

.logistics-select-main__week-selall {
	border-right: 2px solid #F6F6F6;
	display: flex;
	justify-content: space-between;
	padding-top: 5px;
}

.logistics-select-main__week-sel__week
{
	display: flex;
	justify-content: space-evenly;
}

.logistics-select-main__week-sel__week--active
{
	background-color: #e4f7ee;
	transition: background-color .5s linear;
}


.logistics-select-main__week-sel__week__check{
	padding-top: 5px;
	/* padding-left: 30px; */
}

.logistics-select-main__week-sel__week__word
{
	border: 2px solid #F6F6F6;
	border-radius: 6px;
	padding: 5px 48px;
	/* width: 150px; */
	background-color: transparent;
	cursor: pointer;
	transition: border .5s linear, color .5s linear, background-color .5s linear;
}
.logistics-select-main__week-sel__week__word--selected
{
	border: 2px solid #CACACA;
	transition: border .5s linear, color .5s linear, background-color .5s linear;
}

.logistics-select-main__week-sel__week__word--active
{
	border: 2px solid #32C57C;
	color: #029F51;
	background-color: #CAF4E2;
	transition: border .5s linear, color .5s linear, background-color .5s linear;
}
.logistics-select-main__week-sel__col__variant-list
{
	display: flex;
	overflow-x: auto;
}

.logistics-select-main__week-sel__col__variant-list::-webkit-scrollbar
{
	height: 2px;
}

.logistics-select-main__week-sel__col__variant-list::-webkit-scrollbar-track
{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
    height: 2px;
}
.logistics-select-main__week-sel__col__variant-list::-webkit-scrollbar-thumb
{
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
} 

.logistics-select-main__week-sel__col__variant
{
	white-space: nowrap;
	padding: 7px 10px;
	border: 2px solid #4AD370;
	border-radius: 6px;
	margin: 0 9px;
	transition: border .5s linear, color .5s linear, background-color .5s linear;
}

/* @media only screen and (max-width: 1439px) { */
@media only screen and (max-width: 1339px) {
	.logistics-select-main__week-sel__week__word
	{
		padding: 1px 0;
		width: 75px;
	}

	.logistics-select-main__week-sel__week__check{
		padding-top: 1px;
		padding-left: 30px;
	}

}

</style>