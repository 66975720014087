<template>
	<div id="logistics">
		<b-row class="logistics__main">
			<b-col sm="12" lg="6">
				<SelectionSect :farm="farm"/>
			</b-col>
			<b-col sm="12" lg="6">
				<DisplaySect/>
			</b-col>
		</b-row>
		<div class="logistics__mobile-err">
			Mobile view is not supported
		</div>
	</div>
</template>

<script>
import DisplaySect from '../components/logistics/display/DisplaySect.vue';
import SelectionSect from '../components/logistics/selection/SelectionSect.vue';
export default {
	name: "SalesAndLogistics",
	props: ['farm'],
	components : {
		SelectionSect,
		DisplaySect,
	},
}
</script>

<style scoped>
.logistics__mobile-err {
	display: none;
  }

@media only screen and (max-width: 1023px) {
	/* For mobile phones: */
	.logistics__mobile-err {
		display: block;
	}
	.logistics__main {
		display: none;
	}
}
</style>