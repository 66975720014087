<template>
	<div id="logistics-select__export" ref="haha">
		Download <button @click="exportCSV">CSV</button> <button @click="exportPDF">PDF</button>

		<!-- export pdf elements -->
		<vue-html2pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="true"
        :preview-modal="true"
        :paginate-elements-by-height="1400"
        :filename="'plantingPlan'+ new Date().toLocaleDateString()"
        :pdf-quality="2"
        :manual-pagination="false"
        pdf-format="a4"
        pdf-orientation="landscape"
        pdf-content-width="1000px"
        ref="html2Pdf"
    >
        <section slot="pdf-content">
            <!-- PDF Content Here -->
			<b-table striped hover :items="computeTableItems()"></b-table>
        </section>
    </vue-html2pdf>
	</div>
</template>

<script>
import VueHtml2pdf from 'vue-html2pdf'

export default {
	name : "Export",
	props : {
		exportSelections : Array,
		planWeeks : Array
	},
	methods : {
		/**
		 * variantAggregation - transform planWeeks data into desierable format for exporting
		 */
		variantAggregation(_planWeeks)
		{
			const res = [];

			if (!_planWeeks)
				return res;
			for (let i = 0; i < _planWeeks.length; i++) {
				const plans = _planWeeks[i];
				if (!plans.length)
					continue;
				for (let j = 0; j < plans.length; j++) {
					const plan = plans[j];
					const capacity = plan.capacity;
					const variants = plan.variants;
					const germinationStartCalandarWeek = plan.germinationStartCalandarWeek;
					const propagationStartCalandarWeek = plan.propagationStartCalandarWeek;
					const harvestStartCalandarWeek = plan.harvestStartCalandarWeek;

					for (let k = 0; k < variants.length; k++) {
						const variant = variants[k];
						res.push({
							week: i,
							capacity,
							name: variant.name,
							percentage: variant.percentage,
							forecastedYieldPerUnit: variant.forecastedYieldPerUnit,
							germinationStart: new Date(germinationStartCalandarWeek).toLocaleDateString(),
							propagationStart: new Date(propagationStartCalandarWeek).toLocaleDateString(),
							harvestStart: new Date(harvestStartCalandarWeek).toLocaleDateString()
						})
					}

					
				}
				
			}

			return res;
		},
		/**
		 * computeTableItems - compute the items which needed to be in the table
		 */
		computeTableItems()
		{
			return this.variantAggregation(this.planWeeks).filter((e) => this.exportSelections[e.week]);
		},
		/**
		 * download - downloads file to user
		 */
		download(filename, text) {
			var element = document.createElement('a');
			element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
			element.setAttribute('download', filename);

			element.style.display = 'none';
			document.body.appendChild(element);

			element.click();

			document.body.removeChild(element);
		},
		/**
		 * exportCSV - parses table data and generates csv, then prompts user to download
		 */
		exportCSV()
		{
			const aggregatedVariants = this.variantAggregation(this.planWeeks)
				.filter((e) => this.exportSelections[e.week]);
			let res = "";
			// csv header
			res += "week,capacity,name,percentage,forecastedYieldPerUnit,germinationStart,propagationStart,harvestStart\n"

			// csv content
			for (let i = 0; aggregatedVariants && i < aggregatedVariants.length; i++) {
				const element = aggregatedVariants[i];

				// weeks column
				res += `${element.week},`

				// capacity column
				res += `${element.capacity},`

				// name column
				res += `${element.name},`
				
				// percentage column
				res += `${element.percentage},`

				// forecastedYieldPerUnit column
				res += `${element.forecastedYieldPerUnit},`

				// germinationStartCalandarWeek column
				res += `${element.germinationStart},`

				// propagationStartCalandarWeek column
				res += `${element.propagationStart},`

				// harvestStartCalandarWeek column
				res += `${element.harvestStart},`

				res += "\n";
			}
			this.download(`plantingPlan${new Date().toLocaleDateString()}.csv`, res)
		},
		exportPDF()
		{
			this.$refs.html2Pdf.generatePdf()
		}
	},
	components : {
		VueHtml2pdf
	},
}
</script>

<style>
#logistics-select__export {
	display: flex;
	align-items: center;
	padding: 9px 16px;
}

#logistics-select__export button {
	margin: 0 6px;
	outline: none;
	background: #32A3FF;
	border: 1.67477px solid #F6F6F6;
	border-radius: 5.0243px;
	padding: 3px 8px;
	color: white;
}
</style>