<template>
	<div id="logistics-display">
		<!--Top bar-->
		<TopBar
		:selectedUnitFilter="selectedUnitFilter"
		@filterChanged="handleFilterChange"
		/>	

		<!--chart-->
		<Charts
		:selectedUnitFilter="selectedUnitFilter"
		/>
	</div>
</template>

<script>
import Charts from './Charts.vue';
import TopBar from './TopBar.vue';

export default {
	name: "DisplaySect",
	components : {
		TopBar,
		Charts,
	},
	data()
	{
		return {
			selectedUnitFilter : "G/UNIT",
		}
	},
	methods : {
		handleFilterChange(newFilter)
		{
			this.selectedUnitFilter = newFilter
		}
	}
}
</script>

<style scoped>
#logistics-display{
	box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.1);
}

.logistics-display__main{
	border-top: 2px solid #F6F6F6;
}
</style>