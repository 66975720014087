<template>
	<div class="scroll" id="logistics-display-chart">
		<div>
			<ApexChart
			type="bar"
			height="350"
			:options="wpuOptions"
			:series="wpuSeries"
			></ApexChart>
		</div>

		<div>
			<ApexChart
			type="bar"
			height="350"
			:options="wbdOptions"
			:series="wbdSeries"
			></ApexChart>
		</div>
	</div>
</template>

<script>
export default {
	name : "Charts",
	props : {
		selectedUnitFilter : String,
	},
	computed:
	{
		selectedPlans(){
			return this.$store.state.selectedPlans;
		},
		selectedVariantFitler()
		{
			return this.$store.state.selectedVariantFitler;
		},
		wpuSeries()
		{
			const variants = [];


			// loop through each variant of each selected plan
			this.selectedPlans.forEach(plan => {
				plan.variants.forEach(variant => {
					variants.push({
						...variant,
						harvestStartCalandarWeek : plan.harvestStartCalandarWeek,
						capacity: plan.capacity,
					})
				});
			});

			// varint set to join duplicates
			const variantSet = new Set();
			variants.forEach(variant => {
				if (!this.selectedVariantFitler || this.selectedVariantFitler == variant.name)
					variantSet.add(variant.name)
			});

			// construct series
			const series = [];
			variantSet.forEach(variantName => {
				// each seriesobj corresponds to one variant
				const seriesObj = {
					name : variantName,
					data : new Array(7).fill(0)
				}

				// find all variantname is variants and push to data 
				
				// let totalQuantity = 0;
				variants.forEach(variant => {
					if (variant.name != variantName) return ;

					// unit or weight switch
					if (this.selectedUnitFilter == "G/UNIT")
						seriesObj.data[new Date(variant.harvestStartCalandarWeek).getDay()] = variant.forecastedYieldPerUnit  
					else
					{
						seriesObj.data[new Date(variant.harvestStartCalandarWeek).getDay()] =
							Math.floor(variant.capacity * (variant.percentage / 100))
					}
				})

				series.push(seriesObj);
			});

			return series	
		},
		wbdSeries()
		{
			const seriesRef = this.wpuSeries;
			const series = [
				{data : [0, 0, 0, 0, 0, 0, 0]},
			];
			
			seriesRef.forEach((element) => {
				series[0].data[0] += element.data[0]
				series[0].data[1] += element.data[1]
				series[0].data[2] += element.data[2]
				series[0].data[3] += element.data[3]
				series[0].data[4] += element.data[4]
				series[0].data[5] += element.data[5]
				series[0].data[6] += element.data[6]

			});
			return series;
		},
		wpuOptions()
		{
			return {
				title: {
				text: this.selectedUnitFilter == "G/UNIT" ? "Weight /Unit per variant" : "Units",
				align: 'left',
				margin: 10,
				offsetX: 0,
				offsetY: 0,
				floating: false,
				style: {
				fontSize:  '19px',
				fontWeight:  'medium',
				fontFamily:  undefined,
				color:  '#263238'
				},
			},
            chart: {
              type: 'bar',
              height: 350
            },
            plotOptions: {
              bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded'
              },
            },
            dataLabels: {
              enabled: false
            },
            stroke: {
              show: true,
              width: 2,
              colors: ['transparent']
            },
            xaxis: {
              categories: ['Sun', 'Mon', 'Tues', 'Weds', 'Thurs', 'Fri', 'Sat'],
            },
            yaxis: {
              title: {
                text: this.selectedUnitFilter == "G/UNIT" ? "g/unit" : "units",
              }
            },
            fill: {
              opacity: 1
            },
            tooltip: {
              y: {
                formatter: function (val) {
                  return val + " units"
                }
              }
            }}
		},
		wbdOptions()
		{
			return {
				title: {
				text: this.selectedUnitFilter == "G/UNIT" ? "Weight by day" : "Units by day",
				align: 'left',
				margin: 10,
				offsetX: 0,
				offsetY: 0,
				floating: false,
				style: {
				fontSize:  '19px',
				fontWeight:  'medium',
				fontFamily:  undefined,
				color:  '#263238'
				},
			},
            chart: {
              type: 'bar',
              height: 350
            },
            plotOptions: {
              bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded'
              },
            },
            dataLabels: {
              enabled: false
            },
            stroke: {
              show: true,
              width: 2,
              colors: ['transparent']
            },
            xaxis: {
              categories: ['Sun', 'Mon', 'Tues', 'Weds', 'Thurs', 'Fri', 'Sat'],
            },
            yaxis: {
              title: {
                text: this.selectedUnitFilter == "G/UNIT" ? "grams/unit" : "units",
              }
            },
            fill: {
              opacity: 1
            },
            tooltip: {
              y: {
                formatter: function (val) {
                  return val + " units"
                }
              }
            }}
		}
	},
	data()
	{
		return {
		}
	}
}
</script>

<style>
#logistics-display-chart{
	padding: 9px 24px;
	height: 510px;
	overflow: auto;
}

/* #logistics-display-chart::-webkit-scrollbar
{
	width: 2px;
}

#logistics-display-chart::-webkit-scrollbar-track
{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
    width: 2px;
}
#logistics-display-chart::-webkit-scrollbar-thumb
{
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
}  */
</style>